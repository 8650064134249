import { FC, useCallback, useMemo } from 'react';
import { useCallbackRef, useUtilities } from '@faxi/web-component-library';
import { InfoCard, InputField } from 'components';
import {
  Form,
  FormField,
  FormProps,
  FormRef,
  validators,
} from '@faxi/web-form';
import { FormActions } from 'Global.styles';
import { useFormButtons } from 'hooks';
import specific, { composeValidators } from 'validation/validators/specific';
import { apiJourneys } from 'modules';
import { useParams } from 'react-router';
import { snackBarSuccessMessage } from 'utils';

import * as Styled from './EditPointsModal.styles';

type EditPointsModalProps = {
  userId: string;
  onClose: () => void;
  onChangePoints: () => void;
  initialPoints?: number;
};

const EditPointsModal = (props: EditPointsModalProps) => {
  const { userId, onClose, onChangePoints, initialPoints } = props;

  const [FormButtons] = useFormButtons(initialPoints ? 'Save changes' : 'Add');
  const [form, formRef] = useCallbackRef<FormRef>();

  const { journeyId } = useParams<{
    journeyId: string;
  }>();

  const { showOverlay, hideOverlay } = useUtilities();

  const validations = useMemo(
    () => ({
      points: composeValidators(
        validators.general.required('This field is required'),
        specific.integerOnly('Please enter a valid positive number'),
        specific.canNotStartWithZeros('Please enter a valid positive number')
      ),
    }),
    []
  );

  const handleSubmitPoints = useCallback(
    async (values: { points: string }) => {
      showOverlay('.wcl-modal__children-wrapper');

      try {
        const { data } = await apiJourneys.addJourneyPoints(
          journeyId,
          userId,
          +values.points
        );

        if (data.status === 'Success') {
          snackBarSuccessMessage('Points successfully changed');
          onClose();
          onChangePoints();
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal__children-wrapper');
      }
    },
    [hideOverlay, journeyId, onChangePoints, onClose, showOverlay, userId]
  );

  const initialFormValues = useMemo(
    () => ({
      points: initialPoints || '',
    }),
    [initialPoints]
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        onSubmit={handleSubmitPoints}
        ref={formRef}
        children={children}
        className={className}
        initialData={initialFormValues}
      />
    ),
    [formRef, handleSubmitPoints, initialFormValues]
  );

  return (
    <Styled.EditPointsModal
      title="Edit points"
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
          />
          <FormButtons.Cancel onClick={onClose} />
        </FormActions>
      }
    >
      <FormField
        name="points"
        component={InputField}
        autoComplete="off"
        className="edit-points-modal__points"
        placeholder={'Points'}
        required
        requiredLabel={'Required'}
        validate={validations.points}
      />

      <InfoCard
        text={
          'All the stats, reports and leaderboards will be calculated based on the new added points.'
        }
        className="edit-points-modal__info"
      />
    </Styled.EditPointsModal>
  );
};

export default EditPointsModal;
