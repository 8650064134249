import { FC, Fragment, useCallback } from 'react';
import { Modal, Tabs, useCallbackRef } from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  FormProps,
  FormProviderProps,
} from '@faxi/web-form';

import { useFormButtons } from 'hooks';
import { Permission, TableAdmin } from 'models';
import { MAP_PERMISSIONS_LABEL, permissionsTabs } from './constants';

import { FormActions } from 'Global.styles';

type PermissionsModalProps = {
  selectedAdmin?: Pick<TableAdmin, 'name'>;
  allPermissions?: Permission[];
  onClose: () => void;
} & Pick<FormProviderProps, 'initialData'> &
  Pick<FormProps, 'onSubmit'>;

const PermissionsModal: FC<PermissionsModalProps> = (
  props: PermissionsModalProps
): JSX.Element => {
  const { onSubmit, initialData, selectedAdmin, onClose, allPermissions } =
    props;

  const [form, formRef] = useCallbackRef<FormRef>();
  const [FormButtons] = useFormButtons('Save changes');

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        initialData={initialData}
        children={children}
        className={className}
      />
    ),
    [formRef, initialData, onSubmit]
  );

  return (
    <Modal
      title="Permissions"
      onClose={onClose}
      className="kinto-modal"
      childrenWrapper={modalForm}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
          />
          <FormButtons.Cancel onClick={onClose} />
        </FormActions>
      }
    >
      {selectedAdmin && (
        <Fragment>
          <div className="kinto-modal__user-banner">
            <div className="kinto-modal__user-banner__name">
              {selectedAdmin.name}
            </div>
          </div>
          {allPermissions && (
            <ul className="kinto-modal__permissions">
              {allPermissions
                .map((permission) => permission.name)
                .sort()
                .filter((_, index) => index % 2 === 0)
                .map((name) => (
                  <li key={name}>
                    <div className="kinto-modal__permissions__permission">
                      <div className="kinto-modal__permissions__permission__name">
                        {
                          MAP_PERMISSIONS_LABEL?.[
                            `${name}` as keyof typeof MAP_PERMISSIONS_LABEL
                          ]
                        }
                      </div>
                      <FormField
                        name={name}
                        component={Tabs}
                        tabs={permissionsTabs}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </Fragment>
      )}
    </Modal>
  );
};

export default PermissionsModal;
