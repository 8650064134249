import { FC, useCallback, useMemo, useRef } from 'react';
import { Form, FormField, FormProps, FormRef, validators } from '@faxi/web-form';
import {
  ModalProps,
  ModalRef,
  useCallbackRef,
} from '@faxi/web-component-library';

import { Icon, TextareaField } from 'components';
import { apiJourneys } from 'modules';
import { useCallbackAsync, useFormButtons } from 'hooks';
import { composeValidators } from 'validation/validators/specific';
import { snackBarSuccessMessage } from 'utils';

import * as Styled from './ExcludeModal.styles';

type ExcludeFormType = { reason: string };

type ExcludeModalProps = Partial<ModalProps> & {
  journeyId: number;
  onExclude?: () => void;
};

const ExcludeModal: FC<ExcludeModalProps> = (props) => {
  const { journeyId, onExclude, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);
  const [FormButtons] = useFormButtons('Exclude', 'Cancel');
  const [form, formRef] = useCallbackRef<FormRef>();

  const validations = useMemo(
    () => ({
      reason: composeValidators(
        validators.general.required('This field is required'),
        validators.general.maxLength(500, `Must contain maximum 500 characters`)
      ),
    }),
    []
  );

  const [handleSubmit, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async (value: ExcludeFormType) => {
      await apiJourneys.markAsUncertain(journeyId, value.reason);

      onExclude?.();
      modalRef.current?.close();
      snackBarSuccessMessage(`Journey ${journeyId} excluded`);
    },
  });

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        onSubmit={handleSubmit}
        strictValidation={false}
        children={children}
        className={className}
        ref={formRef}
      />
    ),
    [formRef, handleSubmit]
  );

  return (
    <Styled.Modal
      ref={modalRef}
      loading={loading}
      childrenWrapper={modalForm}
      className="kinto-exclude-modal"
      title={`Do you really want to exclude this journey?`}
      subtitle={`The journey will be excluded from stats and reports`}
      titleSize="small"
      footer={
        <>
          <FormButtons.Cancel
            onClick={() => {
              modalRef.current?.close();
            }}
          />
          <FormButtons.Submit
            iconPosition="right"
            variant="delete-ghost"
            icon={<Icon name="ban" />}
            disabled={!form?.syncFormValid}
          />
        </>
      }
      {...rest}
    >
      <FormField
        name="reason"
        required
        label="Reason"
        requiredLabel="Required"
        placeholder="Type here..."
        component={TextareaField}
        validate={validations.reason}
      />
    </Styled.Modal>
  );
};

export default ExcludeModal;
