import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { ModalRef, useCallbackRef } from '@faxi/web-component-library';
import { Form, FormProps, FormRef, useFormRefValues } from '@faxi/web-form';

import { dateFormat } from 'config';
import { useCallbackAsync, useFormButtons } from 'hooks';
import { apiRewards } from 'modules';
import { Reward } from 'models';
import { snackBarErrorMessage, snackBarSuccessMessage } from 'utils';

import ActivatePerCommunityStep from '../ActivatePerCommunityStep';
import UploadCsvStep from '../UploadCsvStep';

import * as Styled from './EditRewardModal.styles';
import { FormActions } from 'Global.styles';

type EditRewardModalProps = {
  reward: Reward;
  onClose: () => void;
  onUpdate: (reward: Reward) => void;
};

const EditRewardModal = (props: EditRewardModalProps) => {
  const { reward, onClose, onUpdate } = props;

  const [communityToggles, setCommunityToggles] = useState<
    Record<number, string>
  >({});

  const [form, formRef] = useCallbackRef<FormRef>();

  const [FormButtons] = useFormButtons('Save changes');

  const modalRef = useRef<ModalRef>(null);
  const formValues = useFormRefValues(form, 'file');

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.wcl-modal__children-wrapper',
    callback: async (values: { name: string; file: any }) => {
      const { name, file } = values;

      const communities = Object.keys(communityToggles).filter(
        (communityToggleKey: string) =>
          (communityToggles as any)?.[`${communityToggleKey}`]
      );

      try {
        const {
          data: { data },
        } = await apiRewards.updateReward(reward.id, name, file, communities);

        if (data) {
          onUpdate({
            ...data,
            created_at: dayjs(data.created_at).format(dateFormat),
          } as Reward);
          onClose();
          snackBarSuccessMessage(`Sodexo reward updated`);
        }
      } catch (e) {
        onClose();
        snackBarErrorMessage((e as any)?.message);
        console.error(e);
      }
    },
  });

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        strictValidation={false}
        children={children}
        className={className}
        initialData={reward}
      />
    ),
    [formRef, handleSubmit, reward]
  );

  useEffect(() => {
    reward?.communities?.forEach((community) => {
      setCommunityToggles((old) => ({
        ...old,
        [`${community.community_id}`]: true,
      }));
    });
  }, [reward]);

  const disableSaveButton = useMemo(() => {
    const currentSelected = Object.keys(communityToggles).filter(
      (communityToggle) => communityToggles[communityToggle as any]
    );

    return (
      reward?.communities
        ?.map((community) => community.community_id)
        ?.sort()
        .toString() !== currentSelected.sort().toString() ||
      formValues?.file?.length !== 0
    );
  }, [communityToggles, formValues?.file, reward?.communities]);

  /*
    if reward is active in some campaign 
    we disable deactivate it from that community in which campaign is created
  **/
  const disabledCommunities = useMemo(() => {
    return reward?.communities
      ?.filter((communites) => communites.activated_at)
      .map((communites) => communites.community_id);
  }, [reward?.communities]);

  return (
    <Styled.Modal
      title="Edit reward"
      onClose={onClose}
      childrenWrapper={modalForm}
      ref={modalRef}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit disabled={!disableSaveButton} />
          <FormButtons.Cancel onClick={onClose} />
        </FormActions>
      }
    >
      <UploadCsvStep
        editMode
        initialCSV={[`${reward.number_of_codes} codes`]}
        form={form}
      />

      <div className="edit-reward-modal__select_communities">
        Enable reward per community
      </div>

      <ActivatePerCommunityStep
        communityToggles={communityToggles}
        setCommunityToggles={setCommunityToggles}
        disabledCommunities={disabledCommunities}
      />
    </Styled.Modal>
  );
};

export default EditRewardModal;
