import styled from 'styled-components';
import { Modal, theme } from '@faxi/web-component-library';

export const EditPointsModal = styled(Modal)`
  .wcl-modal {
    width: fit-content;
    min-width:  ${theme.sizes.SIZE_472};
  }

  .edit-points-modal {
    &__points {
      margin-bottom: ${theme.sizes.SIZE_10};
      max-width: ${theme.sizes.SIZE_160};
    }

    &__info {
      max-width: ${theme.sizes.SIZE_408};
      font-size: ${theme.fontSizes.FONT_18};
    }
  }

  .kinto-modal__actions {
    margin-top:  ${theme.sizes.SIZE_8};
  }
`;
