import { FC, useCallback, useMemo } from 'react';
import { useCallbackRef, useUtilities } from '@faxi/web-component-library';
import { InfoCard, InputField } from 'components';
import {
  Form,
  FormField,
  FormProps,
  FormRef,
  validators,
} from '@faxi/web-form';
import { FormActions } from 'Global.styles';
import { useFormButtons } from 'hooks';
import specific, { composeValidators } from 'validation/validators/specific';
import { apiJourneys } from 'modules';
import { useParams } from 'react-router';

import * as Styled from './AddDistanceModal.styles';
import { snackBarSuccessMessage } from 'utils';

type AddDistanceModalProps = {
  userId: string;
  onClose: () => void;
  onChangeDistance: () => void;
  initialDistance?: number;
};

const AddDistanceModal = (props: AddDistanceModalProps) => {
  const { userId, onClose, onChangeDistance, initialDistance } = props;

  const [FormButtons] = useFormButtons(
    initialDistance ? 'Save changes' : 'Add'
  );
  const [form, formRef] = useCallbackRef<FormRef>();

  const { journeyId } = useParams<{
    journeyId: string;
  }>();

  const { showOverlay, hideOverlay } = useUtilities();

  const validations = useMemo(
    () => ({
      distance: composeValidators(
        validators.general.required('This field is required'),
        specific.decimalsAllowed('Please enter a valid positive number'),
        specific.maxNumber('Maximum value for distance is 22.000km', 22000)
      ),
    }),
    []
  );

  const handleSubmitDistance = useCallback(
    async (values: { distance: string }) => {
      showOverlay('.wcl-modal__children-wrapper');

      try {
        const { data } = await apiJourneys.addJourneyDistance(
          journeyId,
          userId,
          +values.distance * 1000
        );

        if (data.status === 'success') {
          snackBarSuccessMessage('New distance added');
          onClose();
          onChangeDistance();
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal__children-wrapper');
      }
    },
    [hideOverlay, journeyId, onChangeDistance, onClose, showOverlay, userId]
  );

  const initialFormValues = useMemo(
    () => ({
      distance: initialDistance || '',
    }),
    [initialDistance]
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        onSubmit={handleSubmitDistance}
        ref={formRef}
        children={children}
        className={className}
        initialData={initialFormValues}
      />
    ),
    [formRef, handleSubmitDistance, initialFormValues]
  );

  return (
    <Styled.AddDistanceModal
      title={`${initialDistance ? 'Edit' : 'Add'} distance`}
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
          />
          <FormButtons.Cancel onClick={onClose} />
        </FormActions>
      }
    >
      <FormField
        name="distance"
        component={InputField}
        autoComplete="off"
        className="add-distance-modal__distance"
        placeholder={'Distance (km)'}
        required
        requiredLabel={'Required'}
        validate={validations.distance}
      />

      {!initialDistance && (
        <InfoCard
          text={
            'All the stats, reports and leaderboards will be calculated based on the new added distance.'
          }
          className="add-distance-modal__info"
        />
      )}
    </Styled.AddDistanceModal>
  );
};

export default AddDistanceModal;
