import { FC, useCallback, useMemo } from 'react';
import { getColor, useCallbackRef } from '@faxi/web-component-library';
import { Form, FormField, FormProps, FormRef, validators } from '@faxi/web-form';
import { Icon, TextareaField } from 'components';
import { useCallbackAsync, useFormButtons } from 'hooks';
import { PassengerTable } from 'models';
import { apiJourneys } from 'modules';
import { snackBarSuccessMessage } from 'utils';
import { composeValidators } from 'validation/validators/specific';

import * as Styled from './ExcludePassengerModal.styles';

type ExcludePassengerModalProps = {
  passenger: PassengerTable & { jid: string };
  lastPassenger: boolean;
  onClose: () => void;
  onExclude: (id: number, reason: string) => void;
};

const ExcludePassengerModal = (props: ExcludePassengerModalProps) => {
  const { passenger, lastPassenger, onClose, onExclude } = props;

  const [FormButtons] = useFormButtons('Exclude', 'Cancel');
  const [form, formRef] = useCallbackRef<FormRef>();

  const validations = useMemo(
    () => ({
      reason: composeValidators(
        validators.general.required('This field is required'),
        validators.general.maxLength(500, `Must contain maximum 500 characters`)
      ),
    }),
    []
  );

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.wcl-modal__children-wrapper',
    callback: async (value: { reason: string }) => {
      const { data } = await apiJourneys.markAsUncertain(
        +passenger.jid,
        value.reason,
        passenger.passenger_id
      );

      if (data.status === 'success') {
        onExclude(passenger.id, value.reason);
        onClose();
        snackBarSuccessMessage(
          `${passenger.screenName} is excluded from the journey`
        );
      }
    },
  });

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        onSubmit={handleSubmit}
        strictValidation={false}
        children={children}
        className={className}
        ref={formRef}
      />
    ),
    [formRef, handleSubmit]
  );

  return (
    <Styled.Modal
      titleSize="small"
      childrenWrapper={modalForm}
      onClose={onClose}
      hasCloseButton={false}
      footer={
        <>
          <FormButtons.Cancel onClick={onClose} />
          <FormButtons.Submit
            iconPosition="right"
            variant="delete-ghost"
            icon={<Icon name="ban" />}
            disabled={!form?.syncFormValid}
          />
        </>
      }
    >
      <h1>
        Do you really want to exclude <span>{passenger.screenName}</span> from
        the journey?
      </h1>

      {lastPassenger && (
        <div className="exclude-passenger-modal__last-passenger">
          <Icon
            name="triangle-exclamation"
            color={getColor('--ALERT_WARNING_1_1')}
          />
          <p>
            Driver will also be excluded because this journey had only two
            participants (driver and one passenger). Also the whole journey will
            be excluded.
          </p>
        </div>
      )}

      <FormField
        name="reason"
        required={lastPassenger}
        label="Reason"
        requiredLabel={lastPassenger ? 'Required' : ''}
        placeholder="Type here..."
        component={TextareaField}
        validate={lastPassenger ? validations.reason : undefined}
      />
    </Styled.Modal>
  );
};

export default ExcludePassengerModal;
