import { FC, useCallback, useMemo } from 'react';
import { JourneyReport } from 'models';
import { Icon } from 'components';
import {
  getColor,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { useFormButtons } from 'hooks';
import { Form, FormField, FormProps, FormRef, validators } from '@faxi/web-form';
import { TextareaField } from 'components';
import { apiJourneys } from 'modules';
import classNames from 'classnames';

import { FormActions } from 'Global.styles';
import * as Styled from './ReportsModal.styles';

type ReportsModalProps = {
  reports: JourneyReport[];
  onClose: () => void;
  revalidate: () => void;
};

const ReportsModal = (props: ReportsModalProps) => {
  const { reports, onClose, revalidate } = props;

  const [FormButtons] = useFormButtons('Add a comment', 'Cancel');

  const [form, formRef] = useCallbackRef<FormRef>();

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const handleOnSubmit = useCallback(
    async (values: { comment: string }) => {
      const { comment } = values;

      showOverlay('.wcl-modal');

      try {
        if (reports[0].jid) {
          const { data } = await apiJourneys.addReportAnswer(
            reports[0].jid,
            comment
          );

          if (data) {
            showSnackBar({
              text: 'Answer successfully added for journey report',
              variant: 'success',
            });

            revalidate();
            onClose();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal');
      }
    },
    [hideOverlay, onClose, reports, revalidate, showOverlay, showSnackBar]
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children }) => (
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        strictValidation={false}
        children={children}
      />
    ),
    [handleOnSubmit, formRef]
  );

  const validations = useMemo(
    () => ({
      comment: [
        validators.general.required('This field is required'),
        validators.general.maxLength(300, 'Maximum length is 300 characters.'),
      ],
    }),
    []
  );

  return (
    <Styled.Modal
      title={`Report (${reports[0].jid})`}
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        !reports?.[0].answered && (
          <FormActions className="kinto-modal__actions">
            <FormButtons.Submit disabled={!form?.syncFormValid} />
            <FormButtons.Cancel onClick={onClose} />
          </FormActions>
        )
      }
    >
      {reports.map((report: JourneyReport) => (
        <div className="reports-modal__report">
          <div className="reports-modal__report__name">
            {report.firstname
              ? `${report.firstname} ${
                  report.lastname ? ` ${report.lastname.slice(0, 1)}.` : ''
                }`
              : report.user_id}
          </div>
          <div className="reports-modal__report__desc">
            <Icon
              color={getColor('--ALERT_ERROR_1_1')}
              name="circle-exclamation-solid"
            />
            <div>{report.description}</div>
          </div>
        </div>
      ))}

      {reports?.[0].answered ? (
        <div className="reports-modal__report">
          <div className="reports-modal__report__name">Admin’s comment</div>
          <div
            className={classNames([
              'reports-modal__report__desc',
              'reports-modal__report__desc--answer',
            ])}
          >
            <Icon color={getColor('--PRIMARY_1_1')} name="message-lines" />
            <div>{reports?.[0].answered.answer}</div>
          </div>
        </div>
      ) : (
        <FormField
          name="comment"
          className="reports-modal__comment"
          component={TextareaField}
          placeholder="Type a comment here..."
          validate={validations.comment}
          noresize
        />
      )}
    </Styled.Modal>
  );
};

export default ReportsModal;
