import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import { Form, FormField, FormProps, FormRef, validators } from '@faxi/web-form';
import {
  useUtilities,
  SelectOption,
  useCallbackRef,
  ModalProps,
  Modal,
  ModalRef,
} from '@faxi/web-component-library';

import {
  getStringError,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';
import { apiCommunities } from 'modules';
import { useFormButtons } from 'hooks';
import { InputField, SelectField, TextareaField } from 'components';
import { CarParkType } from 'models/CarPark';
import specific, { composeValidators } from 'validation/validators/specific';

import { FormActions } from 'Global.styles';

const maxLength = (length: number) =>
  validators.general.maxLength(
    length,
    `Must contain maximum ${length} characters`
  );

const maxValue = (maxValue: number) =>
  specific.maxNumber(`Maximum value for this field is ${maxValue}`, maxValue);

const minValue = (minValue: number) =>
  specific.minNumber(`Minimum value for this field is ${minValue}`, minValue);

const required = validators.general.required('This field is required');
const decimalsAllowed = specific.decimalsAllowed('Please input only numbers');
const negativeDecimalsAllowed = specific.negativeDecimalsAllowed(
  'Please input only numbers'
);
const JSONValidation = specific.validateJSON('Please enter valid JSON format');
const numberValidation = specific.integerOnly('Please input only numbers');

const ACTION_TYPE_OPTIONS = [
  {
    label: 'JSON',
    value: 'JSON',
  },
  {
    label: 'SAGOS',
    value: 'SAGOS',
  },
] as Array<SelectOption<CarParkType['action_type']>>;

export type CarParkFormType = Omit<CarParkType, 'id'>;

type CarParkModalProps = {
  className?: string;
  communityId: string;
  carPark?: CarParkType;
  onSave?: (values: CarParkType) => void;
} & ModalProps;

type InitalCarParkFormValues = Partial<
  Omit<CarParkType, 'latitude' | 'longitude' | 'radius'> & {
    latitude: string;
    longitude: string;
    radius: string;
  }
>;

const CarParkModal: FC<CarParkModalProps> = (props) => {
  const { className, communityId, carPark, onSave, ...rest } = props;

  const { showOverlay, hideOverlay } = useUtilities();

  const modalRef = useRef<ModalRef>(null);

  const [initialData, setInitialData] = useState<InitalCarParkFormValues>({
    action_type: 'JSON',
    json: '{}',
  });

  const [selectedIds, setSelectedIds] = useState<{
    id: string;
    id_organisation: number;
  }>({ id: '', id_organisation: NaN });

  const [FormButtons] = useFormButtons('Save changes', 'Cancel');
  const [form, formRef] = useCallbackRef<FormRef>();

  const isActionTypeJSON = useMemo(
    () => form?.fields['action_type']?.value === 'JSON',
    [form?.fields]
  );

  const handleOnSubmit = useCallback(
    async (values: CarParkFormType) => {
      try {
        showOverlay('.wcl-modal');

        const carParkData = {
          ...values,
          type: 'carpark',
          id: +selectedIds.id,
        } as CarParkType;

        const handleOnAction = (text: string, data: CarParkType) => {
          snackBarSuccessMessage(text);
          onSave?.(data as CarParkType);
          modalRef.current?.close();
        };

        if (carPark) {
          const {
            data: { data: updatedPark },
          } = await apiCommunities.updateCarPark(
            communityId,
            carParkData.id,
            carParkData
          );
          handleOnAction('Car park has been updated successfully', updatedPark);
          return;
        }

        const {
          data: { data: newPark },
        } = await apiCommunities.createCarPark(communityId, carParkData);

        handleOnAction('Car park has been created successfully', newPark);
      } catch (e) {
        console.error(e);
        snackBarErrorMessage(getStringError(e as AxiosError));
      } finally {
        // TODO: Joco check
        // Because of transition animation, when spinner displayed, it appears to have a flashing effect (flashes once)
        // when closing. Thus I had to add timeout to avoid this.
        // idea: maybe add loading feature in modal?
        setTimeout(() => {
          hideOverlay('.wcl-modal');
        }, 200);
      }
    },
    [showOverlay, selectedIds.id, carPark, communityId, onSave, hideOverlay]
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children }) => (
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={initialData}
        strictValidation={false}
        children={children}
      />
    ),
    [handleOnSubmit, formRef, initialData]
  );

  useEffect(() => {
    if (!carPark) return;

    const { id, id_organisation, type, ...rest } = carPark;

    setInitialData({
      ...rest,
      latitude: rest.latitude?.toString(),
      longitude: rest.longitude.toString(),
      radius: rest.radius.toString(),
      spaces_total: rest.spaces_total === '-' ? '' : rest.spaces_total,
      spaces_shared: rest.spaces_shared === '-' ? '' : rest.spaces_shared,
      spaces_other: rest.spaces_other === '-' ? '' : rest.spaces_other,
    });

    setSelectedIds({
      id: `${id}`,
      id_organisation: id_organisation,
    });
  }, [carPark]);

  return (
    <Modal
      title={carPark ? 'Update car park' : 'Add car park'}
      className={className}
      childrenWrapper={modalForm}
      ref={modalRef}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={
              !form?.isFormChanged(!isActionTypeJSON ? ['json'] : []) ||
              !form?.syncFormValid
            }
          />
          <FormButtons.Cancel
            onClick={() => {
              modalRef.current?.close();
            }}
          />
        </FormActions>
      }
      {...rest}
    >
      <div className="kinto-modal__grid-fields">
        <FormField
          name="name"
          className="kinto-modal__grid-fields__name"
          component={InputField}
          required
          placeholder="Name"
          requiredLabel="Required"
          validate={composeValidators(required, maxLength(50))}
        />

        <FormField
          name="latitude"
          className="kinto-modal__grid-fields__lat"
          component={InputField}
          placeholder="Latitude"
          required
          requiredLabel="Required"
          validate={composeValidators(
            required,
            negativeDecimalsAllowed,
            minValue(-90),
            maxValue(90)
          )}
        />

        <FormField
          name="longitude"
          className="kinto-modal__grid-fields__lng"
          component={InputField}
          placeholder="Longitude"
          required
          requiredLabel="Required"
          validate={composeValidators(
            required,
            negativeDecimalsAllowed,
            minValue(-180),
            maxValue(180)
          )}
        />

        <FormField
          name="radius"
          className="kinto-modal__grid-fields__radius"
          component={InputField}
          placeholder="Radius"
          required
          requiredLabel="Required"
          validate={composeValidators(
            required,
            decimalsAllowed,
            maxValue(1000000)
          )}
        />

        <FormField
          name="action_type"
          className="kinto-modal__grid-fields__action-type"
          component={SelectField}
          placeholder="Type"
          options={ACTION_TYPE_OPTIONS}
        />

        {isActionTypeJSON && (
          <FormField
            name="json"
            className="kinto-modal__grid-fields__json"
            component={TextareaField}
            placeholder="JSON"
            validate={JSONValidation}
            noresize
          />
        )}

        <FormField
          name="spaces_total"
          className={classNames('kinto-modal__grid-fields__numocs', {
            'kinto-modal__grid-fields__numocs--without-json': !isActionTypeJSON,
          })}
          component={InputField}
          placeholder="Number of car spaces"
          validate={numberValidation}
        />

        <FormField
          name="spaces_shared"
          className="kinto-modal__grid-fields__numocs"
          component={InputField}
          placeholder="Number of car spaces for car sharers"
          validate={numberValidation}
        />

        <FormField
          name="spaces_other"
          className="kinto-modal__grid-fields__numocs"
          component={InputField}
          placeholder="Number of car spaces for others"
          validate={numberValidation}
        />

        <FormField
          name="passphrase"
          className="kinto-modal__grid-fields__numocs"
          component={InputField}
          placeholder="Passphrase"
          validate={maxLength(40)}
        />
      </div>
    </Modal>
  );
};

export default memo(CarParkModal);
