import { ReactElement } from 'react';
import { Device } from './Device';
import { Event } from './Event';
import { Participant } from './Participant';

export const JOURNEY_STATUS = {
  ACTIVE: 'ACTIVE',
  ABORTED: 'ABORTED',
  FINISHED: 'FINISHED',
  ACTIVE_NO_PASSENGER: 'ACTIVE_NO_PASSENGER',
  FINISHED_NO_PASSENGER: 'FINISHED_NO_PASSENGER',
} as const;
export type JourneyStatus =
  (typeof JOURNEY_STATUS)[keyof typeof JOURNEY_STATUS];

export const JOURNEY_PARTICIPANT_TYPE = {
  WALKER: 'walker',
  CYCLER: 'cycler',
  DRIVER: 'driver',
  PASSENGERS: 'passengers',
} as const;

export type JourneyParticipantType =
  (typeof JOURNEY_PARTICIPANT_TYPE)[keyof typeof JOURNEY_PARTICIPANT_TYPE];

export type Location = {
  type: 'Point';
  coordinates: Array<number>;
  lat: number;
  lng: number;
};

export type JourneyParticipant = {
  id: number;
  pocs_time: string;
  user_id: string;
  user_email: string;
  username: string;
};

export type JourneyReportAnswer = {
  answer: string;
  by_admin: number;
  time: string;
};

export type JourneyReport = {
  user_id: number;
  description: string;
  firstname: string;
  lastname: string;
  status: string;
  time: string;
  jid?: number;
  answered?: JourneyReportAnswer;
};

export type Journey = {
  created_at: string;
  booking_id: number | null;
  // AB - Home To Work, BA - Work To Home
  direction: 'AB' | 'BA';
  start_point: Location;
  end_point: Location;
  ended: string;
  started: string;
  id: number;
  number_of_passengers: number;
  organisation_id: number;
  pocs_time: string | ReactElement;
  pocs_reason: string;
  pocs_type: string;
  status: JourneyStatus;
  aborting_reason?: string;
  type: string;
  ticket: string;
  uncertain?: boolean;
  user: {
    id_user: number;
    user_email: string;
    user_vrm: string;
    home_location?: Location;
  };
  user_id: number;
  participants: Record<Partial<JourneyParticipantType>, JourneyParticipant>;
  reported?: JourneyReport[];
};

export type JourneyDetails = Omit<
  Journey,
  'started' | 'ended' | 'number_of_passengers' | 'id'
> & {
  start_time: string;
  end_time: string;
  participants_count: number;
  coordinates: string;
  vehicle_registration: string;
  email: string;
  id: number;
} & Pick<Participant, 'distance' | 'points' | 'system_distance' | 'pocs_time'>;

export type JourneyResponse = {
  events: Array<Event>;
  journey: JourneyDetails;
  participants: Array<Participant>;
  devices: Array<Device>;
};
